<template>
  <!-- 首页 -->
  <div class="linkView"></div>
</template>
<script>
export default {
  name: 'linkView',
  props: {
    ifmUrl: {
        type: String,
        default: ""
    },
    clickStatus:{
        type: String,
        default: ""
    }
  },
  data() {
    return {
      ok: false, // 是否加载完成
      pdfh5: null,
      host: '',
      url: ''
    }
  },
  watch: {
    ifmUrl: {
      handler(n) {
        if (n) {
          let link = this.ifmUrl
          var u = navigator.userAgent
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
          if (isAndroid) {
            //android终端
            window.open(link)
          } else if (isiOS) {
            //ios终端
            window.location.href = link
          } else {
            window.open(link)
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.linkView {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  .container {
    background: #f6f6f6;
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
    // 主体
    .ConsultingCenter {
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 0;
    }
  }
}
</style>