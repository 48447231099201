<template>
  <div>
    <div class="verify-text">{{text}}</div>
    <div class="verify-options">
      <div class="verify-option" v-for="(option, index) in options" :key="index" @click="selectOption(index)">
        {{option}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String, // 需要验证的文字
    options: Array // 选项
  },
  data () {
    return {
      selectedOption: -1, // 当前选择的选项
      correctIndex: Math.floor(Math.random() * this.options.length) // 正确选项的索引
    }
  },
  methods: {
    // 选择选项
    selectOption: function (index) {
      if (this.selectedOption === -1) { // 第一次选择
        this.selectedOption = index
      } else {
        this.$emit('verified', index === this.correctIndex) // 发送验证结果
        this.selectedOption = -1 // 重置选择
      }
    }
  }
}
</script>

<style lang="less" scoped>
.verify-text { font-size: 18px; margin-bottom: 10px; }
.verify-options { display: flex; flex-wrap: wrap; justify-content: space-around; }
.verify-option { width: 40%; margin-bottom: 10px; padding: 10px 20px; text-align: center; border-radius: 5px; cursor: pointer; background-color: #EAF0F5; }
.verify-option:hover { background-color: #D6E4EC; }
</style>