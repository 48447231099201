<template>
    <div
        class="text_verification"
    >
        <input
            v-model="enterCode"
            type="text"
            :placeholder="'验证码'"
            style="width:100px;"
        />
        <div class="show_identify">
            <Identify :identifyCode="identifyCode"></Identify>
        </div>
        <div class="changeCode" @click="refreshCode">看不清楚，换一张</div>
    </div>
</template>
<script>
import Identify from "./identify";
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Identify
    },
    data() {
        return {
            identifyCode: "",
            identifyCodes: "123456789ABCDEFGHGKMNPQRSTUVWXYZabcdefghgkmnpqrstuvwxyz", // 验证码规则
            enterCode: "", // 输入的验证码
        }
    },
    watch: {
        show: {
            handler(n) {
                if (n) {
                    this.enterCode="";
                    this.refreshCode();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        
    },
    methods: {
        // 切换验证码
        refreshCode() {
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
            this.$emit("getIdentifyCodesFun",this.identifyCode)
            // console.log(this.identifyCode);
        },
        // 生成随机验证码
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode +=
                this.identifyCodes[
                    Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
                ];
            }
        },
    }
}
</script>
<style lang="less" scoped>
.text_verification {
    margin-bottom: 30px;
    display: flex;
    padding-left: 22px;
    height: 84px;
    .up1 {
      width: 176px;
      padding: 0;
      padding-left: 16px;
    }
    .show_identify {
    }
    .changeCode {
      height: 100%;
      line-height: 84px;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.7);
      color: #000;
      font-size: 20px;
      padding: 0 12px;
    }
}
</style>