<template>
  <!-- 首页 -->
  <div class="pdfView">
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>
<script>
export default {
  name: 'pdfView',
  props: {
    // 最小可选日期
    pdf: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ok: false, // 是否加载完成
      pdfh5: null,
      host: '',
      url: ''
    }
  },
  watch: {
    pdf: {
      handler(n) {
        if (n) {
          console.log(this.pdf,'sdhjsjhsjhdhshp[pp]')
          let link = this.pdf
          var u = navigator.userAgent
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
          this.host = link.slice(0, parseInt(link.indexOf('v8filesvr')))
          this.url = this.host + 'pdf/web/viewer.html?file=' + this.pdf;
          console.log(this.url,'dsddsdsdds')
          if (isAndroid) {
            //android终端
            window.open(this.url)
          } else if (isiOS) {
            //ios终端
            window.location.href = this.url
          } else {
            window.open(this.url)
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.pdfView {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  .container {
    background: #f6f6f6;
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
    // 主体
    .ConsultingCenter {
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 0;
    }
  }
}
</style>